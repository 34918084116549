<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-4">
                    <div class="text-center">
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
						<p v-b-modal.modal-3 variant="link" class="mb-0"><u>수집이 잘 되지 않는 경우 조치방법</u></p>
							<b-modal id="modal-3" size="sm" title="수집이 잘 되지 않는 경우 조치방법" ok-only>
								<p>1. 모든 크롬 탭을 닫고 새탭을 열어 쿠팡윙에 로그인하고 새탭을 열어 쿠플러스에 다시 로그인 후에 다시 시도하기</p>
                                <p>2. http://www.couplus.co.kr 링크로 다시 접속해서 시도하기</p>
								<p>3. 크롬 브라우저 사용하기</p>
								<p>4. 확장앱 중 1688/타오바오/알리바바 이미지서치 앱 삭제</p>
								<p>5. 크롬 브라우저 삭제 후 재설치</p>
                            </b-modal>
                    </div>
                </div>
			</div>
			<div class="col-lg-12">
				<div class="d-flex justify-content-center" style="height:150%">
					<input type="text" class="form-control w-25 h-100 text-center rounded-pill" placeholder="브랜드명(생략가능)" v-model="brandName">
					<input type="text" class="ml-2 form-control w-25 h-100 text-center rounded-pill" placeholder="상품특징(생략가능)" v-model="productspec">
					<input ref="cursor" type="text" class="ml-2 form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="searchenter($event)">
				</div>
			</div>
			<div class="col-lg-12 mt-4 mb-5">
				<div class="d-flex justify-content-center align-items-center">
					<button type="button" class="btn btn-primary" @click="search">상품명 생성</button>
					<!-- <div class="custom-control custom-switch custom-control-inline ml-3">
						<input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="related">
						<label class="custom-control-label" for="customSwitch1">연관검색어</label>
					</div> -->
				</div>
			</div>
			<div class="col-lg-12 d-flex justify-content-center text-center mt-5" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{loadingtext}}... {{loadingrate}}</span>
						</div>
					</div>
				</div>
			</div>
			
			
					


			<div class="col-lg-12 d-flex justify-content-center text-center" v-if="showlist">
				<div class="w-100 h-100">
					<h5>상품명</h5>
					<div class="d-flex justify-content-center">
						<card class="p-3 h-100 w-75">
							<v-row>
								<v-col class="d-flex justify-content-start">
									<v-chip v-for="(keyword, index) in keywords" :key="index" class="ma-1 ml-1" close draggable @click:close="removeChip(index)" @dragstart="dragStart(index)" @dragend="dragEnd" @dragover.prevent @drop="drop(index)">
										{{ keyword.keyword }}
									</v-chip>
									<v-chip @dragover.prevent @drop="droplast" style="background-color:white;width:50px">

									</v-chip>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<b-button v-clipboard:copy="fullProductName" variant="outline-primary">상품명복사</b-button>
								</v-col>
							</v-row>
						</card>
					</div>
				</div>
			</div>
			
			<div class="col-lg-12 d-flex justify-content-center text-center" v-if="showlist">
				<div class="w-100 h-100">
					<h5>태그(검색어)</h5>
					<div class="d-flex justify-content-center">
						<card class="p-3 h-100 w-75">
							<v-row>
								<v-col cols="3" v-for="(keyword, index) in relkeywords" :key="index" class="d-flex justify-content-start">
									<v-chip class="ma-1 ml-1" close draggable @click:close="removeChiprel(index)" @dragstart="dragStartrel(index)" @dragend="dragEndrel(index)" @dragover.prevent @drop="droprel(index)">
										{{ keyword.keyword }}
									</v-chip>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<b-button v-clipboard:copy="fullTag" variant="outline-success">태그복사</b-button>
								</v-col>
							</v-row>
						</card>
					</div>
				</div>
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
// import axios from 'axios';
import * as XLSX from 'xlsx';
// import { chrome } from 'vue-chrome-extension';
export default {
	name:'keywordoptimize',
	data(){
		return{
			related:false,
			auth:'',
			loadingtext:'연관검색어 분석 중',
			loadingrate:'',

			showspinner:false,
			showlist:false,

			waitresult:false,
			targetKeyword:'',
			fullsearch:false,
			addlines: 10,
			rowlines:[],

			brandName:'',
			productspec:'',

			title:"상품명 메이커",
			desc1:"쿠팡 상품명과 태그를 최적화된 키워드로 자동으로 생성합니다.",
			desc2:"키워드가 부족한 경우 자동으로 키워드 확장을 실행합니다.",
			desc3:"브랜드명과 상품특징은 상품명에 가장 먼저 작성됩니다.",
			items: [
				{ text: "Text Box 1" },
				{ text: "Text Box 2" },
				{ text: "Text Box 3" },
			],
			spacing: 10,
			draggingIndex: null,
			draggingIndexrel: null,
			keywords: [],
			relkeywords: [],
			insertKeyword:null,
			insertrelKeyword:null,
			maxkeywordlength:5,
			maxrelkeywordlength:20,
			optimizedKeywords:[],
			draggedtarget:'',
			remainKeywords:[],
			allkeywords:[],

			fullProductName:"",
			fullTag:"",

			// selectedKeyword: null,
			// keywords: []
		}
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	mounted(){
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'totalrelatedkeywords'){
				var arr = event.data.relatedkeywords;
				this.allkeywords = arr
				console.log(arr)
				this.optimizing(arr)
				
				this.showlist = true;
            }		
			if(event.data.res && event.data.res === 'addrelkeywords'){
				arr = event.data.relatedkeywords;
				console.log(arr)
				this.optimizingadd(arr)
            }	
			if(event.data.res && event.data.res === 'relkeywordsrate'){
				this.loadingrate = (event.data.rate*100).toFixed(0) + '%';
            }	
        }
		window.addEventListener('message', this.messageEventHandler);
		console.log(navigator)
		this.startfocus();
	},
	methods: {
		makeProductName(){
			this.fullProductName = this.keywords.map(e => e.keyword).join(" ").trim();
			console.log(this.fullProductName)
		},
		makeTag(){
			this.fullTag = this.relkeywords.map(e => e.keyword).join(",").trim();
			console.log(this.fullTag)
		},
		copyProductName(){

		},
		copyTag(){
			
		},
		addKeyword() {
			this.keywords.push(this.insertKeyword);
			this.insertKeyword = null;
		},
		dragStart(index) {
			this.draggedIndex = index;
			this.draggedtarget = 'keyword';
		},
		dragEnd() {
			this.draggedIndex = null;
		},
		drop(index) {
			if(this.draggedtarget == 'keyword'){
				var draggedKeyword = this.keywords[this.draggedIndex];
				this.keywords.splice(this.draggedIndex, 1);
				this.keywords.splice(index, 0, draggedKeyword);
			} else {
				if(this.remainKeywords.length > 0){
					draggedKeyword = this.relkeywords[this.draggedIndexrel];
					this.removeChiprel(this.draggedIndexrel);
					var slicearr = this.keywords.slice(0,index)
					slicearr.push(draggedKeyword)
					this.keywords = slicearr.concat(this.keywords.slice(index,this.keywords.length));
				}
			}
			this.makeProductName()
			this.makeTag()
		},
		droplast(){
			if(this.draggedtarget == 'relkeyword'){
				this.keywords.push(this.relkeywords[this.draggedIndexrel]);
				this.removeChiprel(this.draggedIndexrel);
			} else {
				this.keywords.push(this.keywords[this.draggedIndex])
				this.keywords = this.keywords.slice(0,this.draggedIndex).concat(this.keywords.slice(this.draggedIndex+1,this.keywords.length));
			}
		},
		droplastrel(){

		},
		removeChip(index) {
			this.keywords = this.keywords.slice(0,index).concat(this.keywords.slice(index+1,this.keywords.length))
			this.makeProductName()
			this.makeTag()
		},
		addKeywordrel() {
			this.relkeywords.push(this.insertrelKeyword);
			this.insertrelKeyword = null;
		},
		dragStartrel(index) {
			this.draggedIndexrel = index;
			this.draggedtarget = 'relkeyword';
		},
		dragEndrel() {
			this.draggedIndexrel = null;
		},
		droprel(index) {
			if(this.draggedtarget == 'relkeyword'){
				var draggedKeyword = this.relkeywords[this.draggedIndexrel];
				this.relkeywords.splice(this.draggedIndexrel, 1);
				this.relkeywords.splice(index, 0, draggedKeyword);
			} else {
				draggedKeyword = this.keywords[this.draggedIndex];
				this.removeChip(this.draggedIndex);
				var slicearr = this.relkeywords.slice(0,index)
				slicearr.push(draggedKeyword)
				this.relkeywords = slicearr.concat(this.relkeywords.slice(index,this.relkeywords.length));
				this.remainKeywords.unshift(this.relkeywords[this.relkeywords.length-1]);
				this.relkeywords.pop();
			}
			this.makeProductName()
			this.makeTag()
		},
		removeChiprel(index) {
			if(this.remainKeywords.length > 0){
				this.relkeywords = this.relkeywords.slice(0,index).concat(this.relkeywords.slice(index+1,this.relkeywords.length));
				this.relkeywords.push(this.remainKeywords[0]);
				this.remainKeywords.shift();
			} else {
				if(confirm("태그 키워드가 부족합니다. 키워드를 추가로 수집하시겠습니까?")){
					this.addrelkeyword()
				}
			}
			this.makeProductName()
			this.makeTag()
		},
		async addrelkeyword(){
			window.postMessage({greeting: "addrelatedkeywords",data:this.allkeywords,opt:this.optimizedKeywords},"*",)
			this.loadingtext = '최적화 키워드 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
		},
		async optimizing(arr){
			arr = arr.filter(e => e)
			this.loadingrate = '';
			this.loadingtext = '키워드 최적화 및 분류작업 중';
			var ar = [];
			var nextar = [];
			for(var i=0;i<arr.length;i++){
				if(arr[i] && arr[i].includes(" ")){
					ar = ar.concat(arr[i].split(" "))
				} else {
					ar.push(arr[i])
				}
			}
			for(i=0;i<ar.length;i++){
				if(ar[i].length > 1 && ar.filter(e => ar[i] && ar[i].includes(e) && ar[i] !== e).length > 0){
					ar.filter(e => ar[i].includes(e) && ar[i] !== e && e.length > 1).forEach(e => {
						if(ar[i].replaceAll(e,"").length > 1){
							ar[i] = ar[i].replaceAll(e," " + e + " ")    
						}
					})
					ar[i].split(" ").forEach(e => {
						if(e.length > 1){
							nextar.push(e)
						}
					})
				} else {
					nextar.push(ar[i])
				}
			}
			// console.log(nextar)

			let freq = [];
			var result = [];
			nextar.forEach(e => {
				if(!freq.find(ele => ele.keyword == e)){
					freq.push({
						keyword:e,
						freq:nextar.filter(ele => ele == e).length,
					})  
					result.push({
						keyword:e,
						freq:nextar.filter(ele => ele == e).length,
					})
				}
			})

			
			freq.sort((a,b) => {return b.freq - a.freq})
			result.sort((a,b) => {return b.freq - a.freq})
			freq = freq.filter(e => e.freq > 1);
			
			var cnt = freq.length;
			if(cnt > 0){
				freq[0].priority = 0;
				result[0].priority = 0;    
			}
			
			for(i=0;i<cnt;i++){
				// console.log(freq[0])
				// console.log(result[0])
				for(var j=0;j<freq.filter(e => e.keyword !== freq[0].keyword).length;j++){
					var e = freq.filter(e => e.keyword !== freq[0].keyword)[j];
					// console.log(e)
					if(arr.filter(ele => ele && ele.includes(e.keyword) && ele.includes(freq[0].keyword)).length > 0){
					var a = arr.filter(ele => ele && ele.includes(e.keyword) && ele.includes(freq[0].keyword))
							.filter(ele => ele && ele.indexOf(e.keyword) < ele.indexOf(freq[0].keyword)).length;
						var b = arr.filter(ele => ele && ele.includes(e.keyword) && ele.includes(freq[0].keyword))
							.filter(ele => ele && ele.indexOf(e.keyword) > ele.indexOf(freq[0].keyword)).length;
						// console.log(a,b)
						if(a>b){
							if(!e.priority){
								result.find(f => f.keyword == e.keyword).priority = freq[0].priority + 1;
								e.priority = freq[0].priority + 1;                    
							} else {
								if(e.priority == freq[0].priority){
									result.find(f => f.keyword == e.keyword).priority++
									e.priority++
								}
							}
						} else {
							if(!e.priority){
								result.find(f => f.keyword == e.keyword).priority = freq[0].priority - 1;
								e.priority = freq[0].priority - 1;
							} else {
								if(e.priority == freq[0].priority){
									result.find(f => f.keyword == e.keyword).priority--
									e.priority--
								}
							}
							
						}
						// console.log(e) 
					}
				}
				freq.shift()
			}
			this.loadingtext = '상품명 키워드 배치 중';
			this.keywords = [];
			
			this.optimizedKeywords = result;
			this.keywords.push(result[0]);
			var ct = 1;
			for(i=0;i<result.length;i++){
				if(result[i].priority !== 0 && result[i].priority){
					this.keywords.push(result[i]);
					await this.sleep(100)
					ct++
					if(ct == this.maxkeywordlength){
						var maxj = ct;
						break
					}
				}
			}

			this.loadingtext = '태그 키워드 배치 중';
			var relcnt = 0;
			this.relkeywords = [];
			for(j=maxj;j<result.length;j++){
				this.relkeywords.push(result[j]);
				await this.sleep(100)
				relcnt++
				if(relcnt == this.maxrelkeywordlength){
					var maxcnt = j+1;
					break
				}
			}
			this.remainKeywords = [];
			for(i=maxcnt;i<result.length;i++){
				this.remainKeywords.push(result[i])
			}
			

			this.loadingtext = '상품명 키워드 순서 최적화 중';
			for (i = 0; i < this.keywords.length; i++) {
				for (j = i + 1; j < this.keywords.length; j++) {
					if (this.keywords[j].priority > this.keywords[i].priority) {
						const temp = this.keywords[i];
						this.keywords[i] = this.keywords[j];
						this.keywords[j] = temp;
						await this.sleep(100)
					}
				}
			}
			if(this.productspec.trim()){
				this.keywords.unshift({keyword:this.productspec.trim()})
			}
			if(this.brandName.trim()){
				this.keywords.unshift({keyword:this.brandName.trim()})
			}

			this.showspinner = false;
			this.makeProductName()
			this.makeTag()
		},
		async optimizingadd(arr){
			this.loadingrate = '';
			this.loadingtext = '키워드 최적화 및 분류작업 중';
			var ar = [];
			var nextar = [];
			for(var i=0;i<arr.length;i++){
				if(arr[i].includes(" ")){
					ar = ar.concat(arr[i].split(" "))
				} else {
					ar.push(arr[i])
				}
			}
			for(i=0;i<ar.length;i++){
				if(ar[i].length > 1 && ar.filter(e => ar[i] && ar[i].includes(e) && ar[i] !== e).length > 0){
					ar.filter(e => ar[i].includes(e) && ar[i] !== e && e.length > 1).forEach(e => {
						if(ar[i].replaceAll(e,"").length > 1){
							ar[i] = ar[i].replaceAll(e," " + e + " ")    
						}
					})
					ar[i].split(" ").forEach(e => {
						if(e.length > 1){
							nextar.push(e)
						}
					})
				} else {
					nextar.push(ar[i])
				}
			}
			// console.log(nextar)

			let freq = [];
			var result = [];
			nextar.forEach(e => {
				if(!freq.find(ele => ele.keyword == e)){
					freq.push({
						keyword:e,
						freq:nextar.filter(ele => ele == e).length,
					})  
					result.push({
						keyword:e,
						freq:nextar.filter(ele => ele == e).length,
					})
				}
			})

			
			freq.sort((a,b) => {return b.freq - a.freq})
			result.sort((a,b) => {return b.freq - a.freq})
			freq = freq.filter(e => e.freq > 1);
			
			var cnt = freq.length;
			if(cnt > 0){
				freq[0].priority = 0;
				result[0].priority = 0;    
			}
			
			for(i=0;i<cnt;i++){
				// console.log(freq[0])
				// console.log(result[0])
				for(var j=0;j<freq.filter(e => e.keyword !== freq[0].keyword).length;j++){
					var e = freq.filter(e => e.keyword !== freq[0].keyword)[j];
					// console.log(e)
					if(arr.filter(ele => ele.includes(e.keyword) && ele.includes(freq[0].keyword)).length > 0){
					var a = arr.filter(ele => ele.includes(e.keyword) && ele.includes(freq[0].keyword))
							.filter(ele => ele.indexOf(e.keyword) < ele.indexOf(freq[0].keyword)).length;
						var b = arr.filter(ele => ele.includes(e.keyword) && ele.includes(freq[0].keyword))
							.filter(ele => ele.indexOf(e.keyword) > ele.indexOf(freq[0].keyword)).length;
						// console.log(a,b)
						if(a>b){
							if(!e.priority){
								result.find(f => f.keyword == e.keyword).priority = freq[0].priority + 1;
								e.priority = freq[0].priority + 1;                    
							} else {
								if(e.priority == freq[0].priority){
									result.find(f => f.keyword == e.keyword).priority++
									e.priority++
								}
							}
						} else {
							if(!e.priority){
								result.find(f => f.keyword == e.keyword).priority = freq[0].priority - 1;
								e.priority = freq[0].priority - 1;
							} else {
								if(e.priority == freq[0].priority){
									result.find(f => f.keyword == e.keyword).priority--
									e.priority--
								}
							}
							
						}
						// console.log(e) 
					}
				}
				freq.shift()
			}
			this.loadingtext = '키워드 추가 중';
			result.forEach(e => {
				if(!this.optimizedKeywords.find(ele => ele.keyword == e.keyword)){
					this.remainKeywords.push(e)
				}
			})

			this.showspinner = false;
			this.showlist = true;
			this.makeProductName()
			this.makeTag()
		},
		resetitems(){
			this.rowlines = [];
		},
		searchenter(event){
			if(event.keyCode === 13){
				this.search();
			}
		},
		startfocus(){
			this.$refs.cursor.focus();
		},
		middlelevel(arr) {
			arr.sort((a,b) => a-b);
			var val = parseInt(arr.length/2);
			return arr[val];
		},
		async makerowlines(coupang,naver,cpc){
			var cpcobj = cpc;
			console.log(coupang)
			console.log(naver)
			console.log(cpc)
			this.rowlines.forEach(e => {
				// console.log(coupang)
				// console.log(e)
				if(coupang.find(ele => ele.keyword == e.targetKeyword)){
					var coupang1p = coupang.find(ele => ele.keyword == e.targetKeyword);
					var coupang1plist = coupang1p.productlists1p.filter(ele => ele.ad == false);
					// console.log(coupang1plist.map(ele => ele.ratingcount))
					var naver1p = naver.find(ele => ele.keyword == e.targetKeyword).keywordvalue;
					var naverlist = naver.find(ele => ele.keyword == e.targetKeyword).products;
					// console.log(naverlist.filter(ele => ele.purchaseCnt >= 0))
					for(var j=0;j<naver1p.length;j++){
						naver1p[j].long = parseInt(naver1p[j].long > naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/26 ? naver1p[j].long : naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/26);
						
					}

					var cpc = cpcobj ? cpcobj[e.targetKeyword] : '';
					
					e.wing1p = coupang1plist.filter(ele => ele.type == 'wing').length;
					e.rocket1p = coupang1plist.filter(ele => ele.type == 'rocket').length;
					e.growth1p = coupang1plist.filter(ele => ele.type == 'jet').length;
					e.wingrate = (e.wing1p/(e.wing1p+e.rocket1p+e.growth1p)*100).toFixed(2);
					e.adqty = coupang1p.adqty;
					e.adplace = coupang1p.adplace;
					e.adrate = (coupang1p.adqty/coupang1p.adplace*100).toFixed(2);
					e.cpchigh = cpc ? cpc.high : 0;
					e.cpclow = cpc ? cpc.low : 0;
					e.cpc = cpc ? cpc.suggested : 0;
					e.rocketqty = coupang1p.rocketqty;
					e.totalqty = coupang1p.totalqty;
					e.rocketrate = (coupang1p.rocketqty/coupang1p.totalqty*100).toFixed(2);
					e.review1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.ratingcount ? pv : cv.ratingcount},0);
					e.review1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.ratingcount ? pv : cv.ratingcount},1000000);
					e.review1p = (coupang1plist.reduce((pv,cv)=>{return pv + cv.ratingcount},0)/coupang1plist.length).toFixed(0);
					e.review1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.ratingcount));

					e.price1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0);
					e.price1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},1000000);
					e.price1p = (coupang1plist.reduce((pv,cv)=>{return pv + cv.price},0)/coupang1plist.length).toFixed(0);
					e.price1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.price));

					e.pricen1phigh = (naver1p.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0)).toFixed(0);
					e.pricen1plow = (naver1p.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},1000000)).toFixed(0);
					e.pricen1p = (((naver1p.reduce((pv,cv)=>{return pv + cv.price},0))/naver1p.length)).toFixed(0);
					e.pricen1pmiddle = (this.middlelevel(naver1p.map(ele => ele.price))).toFixed(0);
					// e.crate = (e.review1p/e.totalqty).toFixed(2);
					e.n1psalesqtyhigh = (naver1p.reduce((pv,cv)=>{return pv > cv.long ? pv : cv.long},0)/7*30).toFixed(0);
					e.n1psalesqtylow = (naver1p.reduce((pv,cv)=>{return pv < cv.long ? pv : cv.long},100000000)/7*30).toFixed(0);
					e.n1psalesqty = (((naver1p.reduce((pv,cv)=>{return pv + cv.long},0))/naver1p.length)/7*30).toFixed(0);
					e.n1psalesqtymiddle = (this.middlelevel(naver1p.map(ele => ele.long))/7*30).toFixed(0);

					e.n1psaleshigh = (naver1p.reduce((pv,cv)=>{return pv > cv.long*cv.price ? pv : cv.long*cv.price},0)/7*30).toFixed(0);
					e.n1psaleslow = (naver1p.reduce((pv,cv)=>{return pv < cv.long*cv.price ? pv : cv.long*cv.price},100000000)/7*30).toFixed(0);
					e.n1psales = (((naver1p.reduce((pv,cv)=>{return pv + cv.long*cv.price},0))/naver1p.length)/7*30).toFixed(0);
					e.n1psalesmiddle = (this.middlelevel(naver1p.map(ele => ele.long*ele.price))/7*30).toFixed(0);
					
				}
			})
			setTimeout(()=>{
				this.loadingtext = '키워드 별 CPC단가 분석 중';
				this.loadingrate = '';
				this.showspinner = false;
				this.showlist = true;
			},1000)


		},
		async sleep(ms) {
			return new Promise((r) => setTimeout(r, ms));
		},
		async importresults(arr){
			await this.sleep(1000);
			this.loadingtext = '키워드 별 CPC단가 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
			window.postMessage({greeting: "keywordanalysis",data:arr,auth:this.auth},"*",)
			// // this.waitresult = false;
			// for(var i=0;i<this.rowlines.length;i++){
			// 	var e = this.rowlines[i];
			// 	await this.sleep(1000)
			// 	if(e.targetKeyword || e.productName || e.itemId || e.productId || e.vendorItemId){
			// 		e.showspinner = true;
			// 		e.rank = [];
			// 		e.adqty = '';
			// 		e.adplace = '';
			// 		e.productqty = '';
			// 		e.rocketqty = '';
			// 		e.totalrank = [];
			// 		var data = {
			// 			targetKeyword : e.targetKeyword,
			// 			productName : e.productName,
			// 			itemId : e.itemId,
			// 			productId : e.productId,
			// 			vendorItemId : e.vendorItemId,
			// 			index : i,
			// 			fullsearch : false,
			// 		}
			// 		// console.log(data)
			// 		window.postMessage({greeting: "coupangrank",data:data},"*",)
			// 		// this.waitresult = true;
			// 	}
			// }
			// // this.waitresult = false;
		},
		changelines(){
			this.rowlines = [];
			for(var i=0;i<this.addlines;i++){
				this.rowlines.push({targetKeyword:'',productName:'',itemId:'',productId:'',vendorItemId:'',rank:[],adqty:'',adplace:'',productqty:'',rocketqty:'',showspinner:false,totalrank:[]})
			}
		},
		resetitem(i){
			this.rowlines[i].targetKeyword = '';
			this.rowlines[i].productName = '';
			this.rowlines[i].itemId = '';
			this.rowlines[i].productId = '';
			this.rowlines[i].rank = [];
			this.rowlines[i].adqty = '';
			this.rowlines[i].adplace = '';
			this.rowlines[i].productqty = '';
			this.rowlines[i].rocketqty = '';
			this.rowlines[i].showspinner = false;
			this.rowlines[i].totalrank = [];
		},
		deleteitem(i){
			this.rowlines = this.rowlines.slice(0,i).concat(this.rowlines.slice(i+1))
			if(this.rowlines.length == 0){
				this.showlist = false;
			}
			this.addlines--
		},	
		search(){
			if(!this.targetKeyword){
				alert('최적화할 키워드를 입력해 주세요')
				return
			}
			window.postMessage({greeting: "gettotalrelatedkeywords",data:[this.targetKeyword]},"*",)
			this.loadingtext = '최적화 키워드 수집 중';
			this.loadingrate = '';
			this.showspinner = true;
			this.showlist = false;
		},
		searchresult(){
			this.saleslists = [];
			this.showlist = false;
			var obj = {};
			var d = {};
			var 이전재고 = '';
			var t = {};
			var su = {};
			var 입고수량 = 0;
			if(this.$store.state.sales.length > 0){
				this.$store.state.sales.sort(function(a,b){
					if (a.date < b.date){
						return -1;
					}
					if (a.date > b.date){
						return 1;
					}
					return 0;
				})	
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				this.$store.state.productdb.forEach(e => {
					if(this.$store.state.sales.filter(ele => ele.판매세부내역.find(element => element.sk == e["SKU ID"])).length == this.$store.state.sales.length){
						obj = {
							썸네일 : e.썸네일,
							판매상품명 : e.판매상품명,
							SKUID : e["SKU ID"],
							바코드 : e.바코드,
							발주가능상태 : e.발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : e.판매링크,
							판매세부내역 : []
						}
						if(this.$store.state.sales.length > 1){
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								//console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									//console.log(su)
									if(su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).length > 0){
										입고수량 = su.importlist.filter(element => element.sku번호.toString() == t.sk.toString()).reduce((pv,rv) => {return pv + parseInt(rv.수량)},0)
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								if(이전재고 == ''){
									이전재고 = t.s ? t.s : 0;
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s+입고수량 ? t.s+입고수량 : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								이전재고 = d.재고 ? d.재고 : 0;
							})
							var salesqty = 0
							for (var j=1;j<obj.판매세부내역.length;j++){
								salesqty = obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 ? obj.판매세부내역[j-1].재고-obj.판매세부내역[j].재고+obj.판매세부내역[j].입고 : 0;
								obj.판매세부내역[j].판매 = salesqty ? salesqty : 0;
								obj.판매 += salesqty ? salesqty : 0;
								obj.판매세부내역[j].판매매출 = salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매매출 += salesqty*obj.판매세부내역[j].판매가 ? salesqty*obj.판매세부내역[j].판매가 : 0;
								obj.판매세부내역[j].공급매출 = salesqty*e.매입가 ? salesqty*e.매입가 : 0;
								obj.공급매출 += salesqty*e.매입가 ? salesqty*e.매입가 : 0;
							}
						} else {
							this.$store.state.sales.forEach(ele => {
								t = ele.판매세부내역.find(element => element.sk == e["SKU ID"]);
								// console.log(t)
								if(this.$store.state.supply.find(element => element.importdate == ele.date)){
									su = this.$store.state.supply.find(element => element.importdate == ele.date);
									// console.log(su)
									if(su.importlist.find(element => element.sku번호.toString() == t.sk.toString())){
										입고수량 = parseInt(su.importlist.find(element => element.sku번호.toString() == t.sk.toString()).수량);
									} else {
										입고수량 = 0;
									}
								} else {
									입고수량 = 0
								}
								d = {
									date : ele.date,
									발주가능상태 : e.발주가능상태,
									재고 : t.s ? t.s : 0,
									판매 : 0,
									입고 : 입고수량 ? 입고수량 : 0,
									판매가 : t.p ? t.p : 0,
									판매매출 : 0,
									공급매출 : 0,
									리뷰수 : t.rq ? t.rq : 0,
									리뷰평점 : t.rp ? t.rp : 0,
									판매링크 : e.판매링크,
								}
								obj.판매세부내역.push(this.deepClone(d))
								obj.재고 = t.s ? t.s : 0;
								obj.판매가 = t.p ? t.p : 0;
								obj.리뷰수 = t.rq ? t.rq : 0;
								obj.리뷰평점 = t.rp ? t.rp : 0;
								obj.판매 = 0;
								obj.입고 += d.입고 ? d.입고 : 0;
								obj.판매매출 = 0;
								obj.공급매출 = 0;
								setTimeout(() => {이전재고 = d.재고 ? d.재고 : 0},1)
							})
						}
						this.saleslists.push(this.deepClone(obj))
						this.saleslistsinit.push(this.deepClone(obj))
					}
				})
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},   
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			this.rowlines.forEach(e => {
				arr.push({
					키워드 : e.targetKeyword,
					쿠팡1p윙비율:e.wingrate,
					쿠팡1p윙제품수 : e.wing1p,
					쿠팡1p로켓제품수 : e.rocket1p,
					쿠팡1p그로스제품수 : e.growth1p,
					쿠팡광고비율 : e.adrate,
					쿠팡광고상품수 : e.adqty,
					쿠팡광고구좌수: e.adplace,
					쿠팡광고CPC단가_추천: e.cpc,
					쿠팡광고CPC단가_최대: e.cpchigh,
					쿠팡광고CPC단가_최저: e.cpclow,
					쿠팡로켓비율: e.rocketrate,
					쿠팡로켓상품수: e.rocketqty,
					쿠팡전체상품수: e.totalqty,

					쿠팡1p리뷰수_평균: e.review1p,
					쿠팡1p리뷰수_최대: e.review1phigh,
					쿠팡1p리뷰수_최저: e.review1plow,
					쿠팡1p리뷰수_중간: e.review1pmiddle,

					쿠팡1p판매가_평균: e.price1p,
					쿠팡1p판매가_최대: e.price1phigh,
					쿠팡1p판매가_최저: e.price1plow,
					쿠팡1p판매가_중간: e.price1pmiddle,

					네이버1p판매가_평균: e.pricen1p,
					네이버1p판매가_최대: e.pricen1phigh,
					네이버1p판매가_최저: e.pricen1plow,
					네이버1p판매가_중간: e.pricen1pmiddle,

					네이버1p월판매수량_평균: e.n1psalesqty,
					네이버1p월판매수량_최대: e.n1psalesqtyhigh,
					네이버1p월판매수량_최저: e.n1psalesqtylow,
					네이버1p월판매수량_중간: e.n1psalesqtymiddle,
					네이버1p월매출_평균: e.n1psales,
					네이버1p월매출_최대: e.n1psaleshigh,
					네이버1p월매출_최저: e.n1psaleslow,
					네이버1p월매출_중간: e.n1psalesmiddle,					
				})
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '키워드분석')
			XLSX.writeFile(workBook, '키워드분석' + '_' + getToday() + '.xlsx')
		},
		settinglist(){
			
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.box {
  position: absolute;
  width: calc(33.33% - 10px);
  min-width: 200px;
  height: 80px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 10px;
}
.box .handle {
  cursor: move;
}
</style>